module.exports = [{
      plugin: require('../plugins/gatsby-plugin-primary-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-root-and-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true},
    },{
      plugin: require('../plugins/gatsby-plugin-cookie-information/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bibendum","short_name":"Bibendum","start_url":"/","background_color":"#ffffff","theme_color":"#2A5135","icon":"src/images/bibendum/favicon.png","include_favicon":false,"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"3aa22901d88557542307a6c4735e6de2"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
